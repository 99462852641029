import React, { useState, useEffect } from "react";
import { ILotModel } from "../../Common/types";
import { fetcherPOSTJSON } from "../../Common/Fetch";


interface IPropsType {
    isOpen: boolean,
    close: () => {},
    theme: string,
    lotDetails: ILotModel,
    lotId: number
}

function DepositLot(props: IPropsType) {

    const [step, setStep] = useState(0);
    const [commission, setCommission] = useState('');
    const [isLegal, setIsLegal] = useState(false);
    const [betValue, setBetValue] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (props.theme == "MitraRealty" || "MitraChina" || "MitraInvest") {
            setCommission('2%');
        } else if (props.theme == "MitraArt") {
            setCommission('3%');
        }

        checkIsLegalUser();

    }, [])


    const setSteps = () => {
        if (isLegal) {
            setTimeout(() => {
                setStep(1);
                //Просит заполнить ЭЦП
            }, 3000)
        } else if (props.lotDetails.DepositIsRequired && !props.lotDetails.IsDepositWrittenOff) {
            setTimeout(() => {
                setStep(2);
            }, 3000)
            //Внесение задатка
        } else {
            setTimeout(() => {
                setStep(3);
            }, 3000)
            //Сделать ставку
        }
    }


    // Проверяем, без юр.силы ли лот
    useEffect(() => {
        if (props.isOpen) {
            if (!props.lotDetails.WithoutLegalForce) {
                checkIsLegalUser();
            } else {
                setSteps();
            }
        }
    }, [props.isOpen])



    //Проверяем разрешено ли пользователю совершать юр. значимые действия
    const checkIsLegalUser = async () => {

        console.log('Проверка разрешено ли пользователю совершать юр. значимые действия');
        const response = await fetch("/TradeLotActionsApi/CheckIsLegalUser", {
            method: "POST",
            headers: { "Accept": "application/json" },
        });
        if (response.ok) {
            let result = await response.json();
            let succesResult = result.Success;
            if (succesResult) {
                setIsLegal(true);
                setSteps();
                console.log(succesResult)
            } else {
                setSteps();
                console.log('Пользователь не может совершать юр.действия')
            }
        } else {
            console.log('error request isLegal');
        }

    }

    //Метод для формирования задатка
    const CreateLotApplicationPrice = async (companyId, lotId) => {
        const responce = await fetch("/LotApplicationPriceApi/CreateLotApplicationPrice",
            {
                method: "POST",
                headers: {
                    "companyId": companyId,
                    "lotId": lotId
                }
            })
    }

    //метод для формирование платёжки задатка
    const GetBillMakingDepositPdf = async (companyId, lotId) => {
        // проверим для укзанного номера лота есть ли у него возможность вносить обеспечительный платеж
        await fetch('/ProfileApi/CheckLotDeposit?lotId=' + lotId,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.Success) {
                        const url = new URL('/Profile/GetBillMakingDepositPdf', window.location.origin);
                        url.searchParams.append('companyId', companyId);
                        url.searchParams.append('lotId', lotId);
                        window.open(url.toString(), '_blank');
                    }
                    else {
                        console.log(result);
                        alert(result.Error);
                    }
                }
            );
    }

    //Сделать ставку
    const makeBet = async () => {
        try {
            const response = await fetch("/Bidding/MakeBet", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    BidAmount: parseFloat(betValue),
                    TradeLotId: props.lotId,
                }),
            });

            const data = await response.json();
            if (!data.Success) {
                console.log('MakeBer' + data.Error);
            } else {
                setStep(4);
                console.log("Ставка сделана!");
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                window.location.href = `/Account/Login?ReturnUrl=${encodeURIComponent(window.location.pathname)}`;
            }
        }
    }

    const makeBetHandle = () => {
        //Проверяем, больше ли минимальной сумма ставки которую указал пользовать
        if (props.lotDetails.MinimumBet > Number(betValue)) {
            setError('Ставка не может быть меньше ' + props.lotDetails.MinimumBet.toString())
        } else {
            makeBet();
        }
    }


    return (
        <div className="defaultPopupWrapper depositLot" style={{ display: props.isOpen ? 'flex' : 'none' }}>
            {step == 0 &&
                <div className="defaultPopup">
                    <div className="popupTitle">
                        <h4>ВНИМАНИЕ! <hr /> </h4>
                    </div>
                    <p className="defaultPopupDescription">Победитель торгов оплачивает {commission} от цены лота при успешной сделке</p>
                </div>
            }

            {step == 1 &&
                <div className="defaultPopup">
                    <button className="closePopupButton" onClick={props.close}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M0.424745 0.15132C0.106868 0.347042 -0.0479959 0.714022 0.0253604 1.11362C0.0742647 1.38274 0.441047 1.79049 2.86181 4.21255L5.6412 7.00975L2.86181 9.79878C0.408444 12.2616 0.0742647 12.6368 0.0172097 12.914C-0.105051 13.5746 0.441046 14.1047 1.11756 13.9823C1.37023 13.9334 1.83482 13.5093 4.20667 11.1444L6.99422 8.36349L9.78991 11.1444C12.1536 13.5093 12.6182 13.9334 12.8709 13.9823C13.5474 14.1047 14.0935 13.5746 13.9712 12.914C13.9142 12.6368 13.58 12.2616 11.1266 9.79878L8.34723 7.00975L11.1266 4.21255C14.1668 1.16255 14.1587 1.17886 13.8897 0.53461C13.8001 0.322577 13.6778 0.200251 13.4659 0.110544C12.822 -0.158573 12.8383 -0.166728 9.80621 2.85881C8.28203 4.37565 7.01052 5.62338 6.97791 5.62338C6.95346 5.62338 5.69825 4.39196 4.19037 2.89143C2.67434 1.38274 1.35393 0.1187 1.23982 0.0779247C0.930089 -0.0444021 0.71002 -0.0199366 0.424745 0.15132Z" fill="#690097" />
                        </svg>
                    </button>
                    <div className="popupTitle">
                        <h4>ПРЕДУПРЕЖДЕНИЕ <hr /> </h4>
                    </div>
                    <p className="defaultPopupDescription">Данный лот является юридически значимым. Для того что бы сделать ставку, подключите электронно-цифровую подпись в личном кабинете.</p>
                    <a href="/Profile/CompanyDetails" className="defaultPinkButton">Ок</a>
                </div>
            }

            {step == 2 &&
                <div className="defaultPopup">
                    <button className="closePopupButton" onClick={props.close}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M0.424745 0.15132C0.106868 0.347042 -0.0479959 0.714022 0.0253604 1.11362C0.0742647 1.38274 0.441047 1.79049 2.86181 4.21255L5.6412 7.00975L2.86181 9.79878C0.408444 12.2616 0.0742647 12.6368 0.0172097 12.914C-0.105051 13.5746 0.441046 14.1047 1.11756 13.9823C1.37023 13.9334 1.83482 13.5093 4.20667 11.1444L6.99422 8.36349L9.78991 11.1444C12.1536 13.5093 12.6182 13.9334 12.8709 13.9823C13.5474 14.1047 14.0935 13.5746 13.9712 12.914C13.9142 12.6368 13.58 12.2616 11.1266 9.79878L8.34723 7.00975L11.1266 4.21255C14.1668 1.16255 14.1587 1.17886 13.8897 0.53461C13.8001 0.322577 13.6778 0.200251 13.4659 0.110544C12.822 -0.158573 12.8383 -0.166728 9.80621 2.85881C8.28203 4.37565 7.01052 5.62338 6.97791 5.62338C6.95346 5.62338 5.69825 4.39196 4.19037 2.89143C2.67434 1.38274 1.35393 0.1187 1.23982 0.0779247C0.930089 -0.0444021 0.71002 -0.0199366 0.424745 0.15132Z" fill="#690097" />
                        </svg>
                    </button>
                    <div className="popupTitle">
                        <h4>Внимание! <hr /> </h4>
                    </div>
                    <p className="defaultPopupDescription">Для участия в данном аукционе необходимо внести задаток. Сформировать платёжное поручение?</p>
                    <button className="defaultPinkButton"
                        onClick={() =>
                        {
                            CreateLotApplicationPrice(props.lotDetails.CurrentCompanyId, props.lotId);
                            GetBillMakingDepositPdf(props.lotDetails.CurrentCompanyId, props.lotId)
                        }}
                    >
                        Сформировать
                    </button>
                </div>
            }

            {step == 3 &&
            <div className="defaultPopup">
                <button className="closePopupButton" onClick={props.close}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path d="M0.424745 0.15132C0.106868 0.347042 -0.0479959 0.714022 0.0253604 1.11362C0.0742647 1.38274 0.441047 1.79049 2.86181 4.21255L5.6412 7.00975L2.86181 9.79878C0.408444 12.2616 0.0742647 12.6368 0.0172097 12.914C-0.105051 13.5746 0.441046 14.1047 1.11756 13.9823C1.37023 13.9334 1.83482 13.5093 4.20667 11.1444L6.99422 8.36349L9.78991 11.1444C12.1536 13.5093 12.6182 13.9334 12.8709 13.9823C13.5474 14.1047 14.0935 13.5746 13.9712 12.914C13.9142 12.6368 13.58 12.2616 11.1266 9.79878L8.34723 7.00975L11.1266 4.21255C14.1668 1.16255 14.1587 1.17886 13.8897 0.53461C13.8001 0.322577 13.6778 0.200251 13.4659 0.110544C12.822 -0.158573 12.8383 -0.166728 9.80621 2.85881C8.28203 4.37565 7.01052 5.62338 6.97791 5.62338C6.95346 5.62338 5.69825 4.39196 4.19037 2.89143C2.67434 1.38274 1.35393 0.1187 1.23982 0.0779247C0.930089 -0.0444021 0.71002 -0.0199366 0.424745 0.15132Z" fill="#690097" />
                    </svg>
                </button>
                <div className="popupTitle">
                    <h4>Сделать ставку <hr /> </h4>
                </div>
                <div className="inputWrapper">
                    <span>Ставка:</span>
                    <input type="text" placeholder={props.lotDetails.MinimumBet.toString()} onChange={(e)=>{setBetValue(e.target.value)}} />
                    <p className="errorText">{error}</p>
                </div>
                <button className="defaultPinkButton" onClick={makeBetHandle}>Подтвердить</button>
            </div>
            }

            {step == 4 &&
                <div className="defaultPopup">
                    <button className="closePopupButton" onClick={props.close}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M0.424745 0.15132C0.106868 0.347042 -0.0479959 0.714022 0.0253604 1.11362C0.0742647 1.38274 0.441047 1.79049 2.86181 4.21255L5.6412 7.00975L2.86181 9.79878C0.408444 12.2616 0.0742647 12.6368 0.0172097 12.914C-0.105051 13.5746 0.441046 14.1047 1.11756 13.9823C1.37023 13.9334 1.83482 13.5093 4.20667 11.1444L6.99422 8.36349L9.78991 11.1444C12.1536 13.5093 12.6182 13.9334 12.8709 13.9823C13.5474 14.1047 14.0935 13.5746 13.9712 12.914C13.9142 12.6368 13.58 12.2616 11.1266 9.79878L8.34723 7.00975L11.1266 4.21255C14.1668 1.16255 14.1587 1.17886 13.8897 0.53461C13.8001 0.322577 13.6778 0.200251 13.4659 0.110544C12.822 -0.158573 12.8383 -0.166728 9.80621 2.85881C8.28203 4.37565 7.01052 5.62338 6.97791 5.62338C6.95346 5.62338 5.69825 4.39196 4.19037 2.89143C2.67434 1.38274 1.35393 0.1187 1.23982 0.0779247C0.930089 -0.0444021 0.71002 -0.0199366 0.424745 0.15132Z" fill="#690097" />
                        </svg>
                    </button>
                    <div className="popupTitle">
                        <h4>Поздравляем! <hr /> </h4>
                    </div>
                    <p className="defaultPopupDescription">Ставка успешно сделана!</p>
                </div>
            }
        </div>
    )
}


export default DepositLot;

