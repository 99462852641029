import React, { useState, useEffect } from "react";
import { IAboutTrade } from "./LotPage";
import { IDocumentModel } from "../../Common/types";
import { ApplicationAcceptance } from "../../TradeApplication/components/ApplicationAcceptance";
import LotWinnerProtocol from "../Document/LotWinnerProtocol";
import { SignStatusses } from "../Document/types";

interface IPropsType {
    aboutTrade: IAboutTrade,
    documents: Array<IDocumentModel>
    lotId: number,
    allowProcessing: boolean,
    allowFinish: boolean
}

//interface IAboutTrade {
//    LotState: string,
//    TradeType: string,
//    DepositRules: string,
//    LotTradePeriodStart: string,
//    LotTradePeriodEnd: string
//}

function AboutTrade(props: IPropsType) {


    return (
        <div className="aboutTrade detailsWrapper">
            <div className="tradeInfo">
                <div className="infoItem">
                    <span className="defaultDataName">Вид торгов:</span>
                    <p className="defaultDataValue">{props.aboutTrade.TradeType}</p>
                </div>

                <div className="infoItem">
                    <span className="defaultDataName">Статус:</span>
                    <p className="defaultDataValue">{props.aboutTrade.LotState}</p>
                </div>

                {props.aboutTrade.DepositRules &&
                    <div className="infoItem">
                        <span className="defaultDataName">Форма проведения торгов и подачи предложений:</span>
                        <p className="defaultDataValue">{props.aboutTrade.DepositRules}</p>
                    </div>   
                }

                <div className="infoItem">
                    <span className="defaultDataName">Начало приема заявок:</span>
                    <p className="defaultDataValue">
                        {
                            new Date(props.aboutTrade.LotApplicationPeriodStart).toLocaleString('ru-RU', {
                                year: '2-digit',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            }).replace(/\//g, '.').replace(',', '')
                        }
                    </p>
                </div>

                <div className="infoItem">
                    <span className="defaultDataName">Окончание приема заявок:</span>
                    <p className="defaultDataValue">
                        {
                            new Date(props.aboutTrade.LotApplicationPeriodEnd).toLocaleString('ru-RU', {
                                year: '2-digit',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            }).replace(/\//g, '.').replace(',', '')
                        }
                    </p>
                </div>

                <div className="infoItem">
                    <span className="defaultDataName">Начало аукциона:</span>
                    <p className="defaultDataValue">
                        {
                            new Date(props.aboutTrade.LotTradePeriodStart).toLocaleString('ru-RU', {
                                year: '2-digit',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            }).replace(/\//g, '.').replace(',', '')
                        }

                    </p>
                </div>

                <div className="infoItem">
                    <span className="defaultDataName">Окончание аукциона:</span>
                    <p className="defaultDataValue">
                        {
                            new Date(props.aboutTrade.LotTradePeriodEnd).toLocaleString('ru-RU', {
                                year: '2-digit',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            }).replace(/\//g, '.').replace(',', '')
                        }
                    </p>
                </div>
            </div>

            {/*<div className="restrictions">*/}
            {/*    <h4 className="defaultDataTitle">Ограничения</h4>*/}

            {/*    <div className="infoItem">*/}
            {/*        <span className="defaultDataName">В залоге в соответствии со 102 ФЗ:</span>*/}
            {/*        <p className="defaultDataValue">Нет</p>*/}
            {/*    </div>*/}

            {/*    <div className="infoItem">*/}
            {/*        <span className="defaultDataName">Сведения о наличии или об отсутствии обременений, судебных споров на момент размещения торгов:</span>*/}
            {/*        <p className="defaultDataValue">Обременение и судебные споры отсутствуют</p>*/}
            {/*    </div>*/}

            {/*    <div className="infoItem">*/}
            {/*        <span className="defaultDataName">Наличие лиц обладающих преимущественным правом покупки лота:</span>*/}
            {/*        <p className="defaultDataValue">Нет</p>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="documents">
                <h4 className="defaultDataTitle">Электронные документы</h4>

                {(props.documents.length >= 1) ? (
                    props.documents.map(doc => {
                        return (
                            <div key={doc.DocumentId} className="documentItem">
                                <span className="defaultDataName">{doc.Name}:</span>
                                <img src="../../main-images/agreementIcon.svg" />
                                <div className="documentInfo">
                                    <a href={doc.DocumentLink}>{doc.Name}</a>
                                    {doc.SignStatusId == SignStatusses.CorrectSign && 
                                        (<a href={"/Document/GetSignature/" + doc.DocumentId}>Скачать подпись</a>)
                                    }
                                    {/*<a>Подписано: Кем-то подписано</a>*/}
                                </div>
                                <p>({
                                    new Date(doc.CreationDate).toLocaleString('en-US', {
                                        year: '2-digit',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit',
                                        hour12: false
                                    }).replace(/\//g, '.').replace(',', '')
                                })</p>
                            </div>
                        )
                    })
                ): (
                    <p className="errorText">Документы отсутствуют</p>
                )}
            </div>

            {props.allowProcessing && <div className="documents">
                <h4 className="defaultDataTitle">Допуск заявителей</h4>
                <ApplicationAcceptance lotId={props.lotId} />
            </div>
            }

            {props.allowFinish && <div className="documents">
                <h4 className="defaultDataTitle">Завершение торгов</h4>
                <LotWinnerProtocol lotId={props.lotId} />
            </div>
            }
        </div>
    )
}


export default AboutTrade;