import { Nullable } from "../types";

export interface IDocument {
    DocumentId: Nullable<number>,
    DocumentName: string,
    Signature: string,
    Thumbprint: string,
    OrganizerSignStatus: number,
    WinnerSignStatus: number,
    WinnerCanSign: boolean,
    OrganizerCanSign: boolean,
    SignStatusDescription: string,
    DataToSign: string
}

export enum SignStatusses {
    CorrectSign = 1,
    CorruptedSign = 2,
    CorruptedFile = 3,
    MissingFile = 4,
    SignDontCorrespondFile = 5,
    SuccessProtocolPartiallySigned = 6
}