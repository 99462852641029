import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import TestArea from "./Test/TestArea";

class TestComponents {
     LoadAll() {

         const testContainer = document.getElementById("testArea");
         if (testContainer) {
            ReactDOM.render(
                <React.Suspense fallback={<div className="loading show"></div>}>
                    <TestArea />
                </React.Suspense>,
                testContainer,
            );
        }
    }
}

export default new TestComponents();