import React, { useState, useEffect } from "react";
import Fetcher from "../Fetch";
import { IDocument, SignStatusses } from "./types";
declare var CADESCOMSignData: any;

interface IProps {
    lotId: number;
}

function LotWinnerProtocol(props: IProps) {
    const { lotId } = props;
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [doc, setDoc] = useState<IDocument>({
        DocumentId: null,
        DocumentName: '',
        SignStatusDescription: '',
        Signature: '',
        OrganizerCanSign: false,
        OrganizerSignStatus: 0,
        Thumbprint: '',
        WinnerCanSign: false,
        WinnerSignStatus: 0,
        DataToSign: ''

    })

    const loadProtocolData = (onLoad: Function) => {
        Fetcher("/TradeEditApi/GetWinnerDocument?lotId=" + lotId,
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.Success) {
                        setDoc(result.UpdatedItem);
                    }
                    onLoad();
                }
            );
    }

    useEffect(() => {
        if (isFirstLoad) {
            loadProtocolData(() => { setIsFirstLoad(false); });
        }
    }, []);

    const handleCreatePdf = () => {
        Fetcher("/TradeEditApi/CreateWinnerDocument?lotId=" + lotId,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.Success) {
                        setDoc(result.UpdatedItem);
                    }
                }
            );
    }

    const handleSignProtocol = () => {
        CADESCOMSignData("hdnSignInfo",
            "hdnSignInfo",
            doc.Thumbprint,
            true,
            "hdnExistingSignData",
            true,
            function (signInfo) {
                console.log(signInfo);

                Fetcher("/TradeEditApi/SignWinnerDocument",
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ SignInfo: signInfo, LotId: lotId })
                    })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result.Success) {
                                setDoc(result.UpdatedItem);
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            }
        );
    }

    if (isFirstLoad) {
        return <div className="col-0"></div>;
    }
    else {
        return (
            <div className="col-0">
                {!doc.DocumentId && <button id="btnCreateDocument" className="btn btn-primary btn-font-14 btn-lg btn-block px-10 btn-sm-mobile" onClick={handleCreatePdf}>Сформировать документ</button>}
                {doc.DocumentId && <div className="col-0">

                    {(doc.OrganizerCanSign || doc.WinnerCanSign) && <div className="signData">
                        <input type="hidden" id="hdnSignInfo" value={doc.DataToSign} />
                        <input type="hidden" id="hdnExistingSignData" value={doc.Signature} />
                    </div>}

                    <div>{doc.DocumentName}</div>
                    <div><a href={"/Document/Details/" + doc.DocumentId} download role="button" className="btn btn-primary btn-font-14 btn-block px-10 btn-sm-mobile">Скачать протокол торгов</a></div>

                    {(doc.WinnerSignStatus == SignStatusses.CorrectSign && doc.OrganizerSignStatus == SignStatusses.CorrectSign) &&
                        <div><a href={"/Document/GetSignature/" + doc.DocumentId} download role="button" className="btn btn-primary btn-font-14 btn-block px-10 btn-sm-mobile">Скачать подпись</a></div>}
                    {/*<div><a href={"/Document/GetSignature/" + doc.DocumentId} download role="button" className="btn btn-primary btn-font-14 btn-block px-10 btn-sm-mobile">Скачать подпись</a></div>*/}
                    <div>
                        {(doc.OrganizerSignStatus == doc.WinnerSignStatus) && (doc.OrganizerSignStatus != 0) ? (<span>
                            {doc.SignStatusDescription}
                        </span>) : (<span><span>{doc.OrganizerSignStatus == SignStatusses.SuccessProtocolPartiallySigned ? "Подписано организатором" : (doc.OrganizerCanSign ? <a href="#" onClick={(e) => { handleSignProtocol() }} title="Подписать">Подписать</a> : "Не подписан организатором")}</span>
                            <span>{doc.WinnerSignStatus == SignStatusses.SuccessProtocolPartiallySigned ? "Подписано победителем" : (doc.WinnerCanSign ? <a href="#" onClick={(e) => { handleSignProtocol() }} title="Подписать">Подписать</a> : "Не подписан победителем")}</span></span>
                        )}
                    </div>
                </div>}
            </div>
        );
    }
}


export default LotWinnerProtocol;