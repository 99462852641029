import React, { useState, useEffect, useRef } from "react";
import InputMask from 'react-input-mask';

function SaleOrigPaintingsPopup() {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [phone, setPhone] = useState('+7 (___) ___-__-__');
    const [isPhone, setIsPhone] = useState<boolean | null>(null);
    const [name, setName] = useState('');
    const [isName, setIsName] = useState<boolean | null>(null);
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [isEmail, setIsEmail] = useState<boolean | null>(null);
    const [checkboxActive, setCheckboxActive] = useState(false);
    const [isAgree, setIsAgree] = useState<boolean | null>(null);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isProgress, setIsProgress] = useState(false);
    
    // Проверка валидации и отправка заявки
    const submitForm = (e) => {
        e.preventDefault();
        setIsProgress(true);

        console.log('submit')

        validateFields();

        if (isPhone && isName && isEmail && isAgree) {
            const params = {
                chat_id: -1002061148970,
                text: `URL: ${document.URL}\n\n ФИО: ${name}\n Номер телефона: ${phone}\n Email: ${email}\n Комментарий: ${message ? message : 'Пользователь не оставил комментарий'}`,
            };

            fetch('https://api.telegram.org/bot7064703080:AAGVqAkyT6-Gy1Y-dMEf0XPM23Ci1lkvx88/sendMessage', {
                method: 'POST',
                body: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    console.log('success feedback' + params + response);
                    setIsSuccess(true); // Скрывает форму и показывает сообщение об успешной отправке формы
                    setIsProgress(false);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    };

    const handlePhone = (e) => {
        setPhone(e.target.value);
    };

    const handleName = (e) => {
        setName(e.target.value);
    };

    const handleMessage = (e) => {
        setMessage(e.target.value);
    };

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    // Валидация полей
    const validateFields = () => {
        let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsPhone(!phone.includes('_'));
        setIsName(name.trim() !== '' && name.split(' ').length >= 2)
        setIsAgree(checkboxActive);
        setIsEmail(emailRegex.test(email));
    };

    // Проверяет валидацию полей при потере фокуса
    const handleBlur = (fieldName) => {
        let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (fieldName === 'phone') setIsPhone(!phone.includes('_'));
        if (fieldName === 'name') setIsName(name.trim() !== '' && name.split(' ').length >= 2);
        if (fieldName === 'agree') setIsAgree(checkboxActive);
        if (fieldName === 'email') setIsEmail(emailRegex.test(email));
    };

    return (
        <React.Fragment>
            <div className="openButton" onClick={() => {setIsOpen(true)}}>

            </div>

            {isOpen &&
                <div className="defaultPopupWrapper">
                    <div className="defaultPopup">
                        <button className="closePopupButton" onClick={() => { setIsOpen(false) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path d="M0.424745 0.15132C0.106868 0.347042 -0.0479959 0.714022 0.0253604 1.11362C0.0742647 1.38274 0.441047 1.79049 2.86181 4.21255L5.6412 7.00975L2.86181 9.79878C0.408444 12.2616 0.0742647 12.6368 0.0172097 12.914C-0.105051 13.5746 0.441046 14.1047 1.11756 13.9823C1.37023 13.9334 1.83482 13.5093 4.20667 11.1444L6.99422 8.36349L9.78991 11.1444C12.1536 13.5093 12.6182 13.9334 12.8709 13.9823C13.5474 14.1047 14.0935 13.5746 13.9712 12.914C13.9142 12.6368 13.58 12.2616 11.1266 9.79878L8.34723 7.00975L11.1266 4.21255C14.1668 1.16255 14.1587 1.17886 13.8897 0.53461C13.8001 0.322577 13.6778 0.200251 13.4659 0.110544C12.822 -0.158573 12.8383 -0.166728 9.80621 2.85881C8.28203 4.37565 7.01052 5.62338 6.97791 5.62338C6.95346 5.62338 5.69825 4.39196 4.19037 2.89143C2.67434 1.38274 1.35393 0.1187 1.23982 0.0779247C0.930089 -0.0444021 0.71002 -0.0199366 0.424745 0.15132Z" fill="#690097" />
                            </svg>
                        </button>

                        {!isSuccess ? 
                            (
                                <>
                                    <div className="popupTitle">
                                        <h4>
                                            Оставьте заявку
                                            <hr></hr>
                                        </h4>
                                        <p>Мы&nbsp;подберём для вас персонализированный портфель активов, учитывая все ваши предпочтения по&nbsp;виду имущества, локации и&nbsp;бюджету</p>
                                    </div>

                                    <div className="popupBody">
                                        <div className="inputWrapper">
                                            <span>ФИО*</span>
                                            <input type="text" placeholder="Введите ФИО" className={isName === false ? 'error' : ''} onChange={handleName} onBlur={() => handleBlur('name')} />
                                        </div>

                                        <div className="inputWrapper">
                                            <span>Номер телефона*</span>
                                            <InputMask
                                                mask="+7 (999) 999-99-99"
                                                onChange={handlePhone}
                                                onBlur={() => handleBlur('phone')}
                                                placeholder="+7 (___) ___-__-__"
                                                className={isPhone === false ? 'error' : ''}
                                            />
                                        </div>

                                        <div className="inputWrapper">
                                            <span>Ваша почта*</span>
                                            <input type="email" placeholder="Почта" className={isEmail === false ? 'error' : ''} onChange={handleEmail} onBlur={() => handleBlur('email')} />
                                        </div>

                                        <div className="inputWrapper">
                                            <span>Комментарий</span>
                                            <textarea placeholder="Введите комментарий" onChange={handleMessage} />
                                        </div>

                                        <div className={`customCheckboxWrapper ${isAgree === false ? 'error' : ''}`}>
                                            <div
                                                className={checkboxActive ? 'customCheckboxActive' : 'customCheckbox'}
                                                onClick={() => {
                                                    setCheckboxActive(!checkboxActive);
                                                    setIsAgree(!checkboxActive);
                                                }}
                                            ></div>
                                            <p className="defaultDescription">Я&nbsp;прочитал(а) и&nbsp;согласен(на) с&nbsp;Политикой обработки персональных данных</p>
                                        </div>

                                        <button className="defaultSubmitButton" onClick={submitForm}>
                                            {isProgress ? (
                                                <img src="/Content/images/loading_sm.gif" style={{ width: '20px' }} />
                                            ) : (
                                                'Отправить данные'
                                            )}
                                        </button>
                                    </div>
                                </>
                            ): (
                                <div className="popupTitle">
                                    <h4>
                                        Успешно!
                                        <hr></hr>
                                    </h4>
                                    <p>Пожалуйста, подождите немного, наш агент скоро свяжется с вами!</p>
                                </div>
                            )

                        }
                        
                    </div>
                </div>        
            }
        </React.Fragment>

    );
}
export default SaleOrigPaintingsPopup;