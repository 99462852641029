/* eslint-disable prettier/prettier */
import { bool, number } from "yup";

export type Nullable<T> = T | null;

export interface ISelectListItem {
    Text: string,
    Value: string,
    Selected: boolean,
    Disabled: boolean,
}

export interface IModelError {
    Key: string,
    Value: Array<string>,
}

export interface IErrorDictionary {
    [key: string]: Array<string>;
}

export interface IFiltersDictionary {
    [key: string]: Array<ILotEditorFilterModel>;
}

// данный enum должен соответствовать enum из Mithra.Data.Domain.OrderServices
export enum OrderStatus {
    // [Description("Ожидаем поступления оплаты")]
    NoPaid = 1,
    // [Description("Оплата поступила")]
    Paid = 2,
    // [Description("В процессе возврата")]
    NeedReturnDeposit = 3,
    // [Description("Возврат осуществлён")]
    DepositReturned = 4
}

//!!! даный enum должен строго соответствовать enum типу на беке в файле - Mithra.Core.Utils.SearchTypes
export enum AuctionTypeSearchType {
    None = 0,
    Promo = 3,
    BuyNow = 2,
    OpenAuction = 1,
    ClosedAuction = 32,
    PublicOffer = 33,
    Competition = 34,
    DecreaseOpenAction = 36,
    OpenAuctionWithInterval = 37
}

export var AuctionTypeDescription: { [key: AuctionTypeSearchType]: string; } = {};
AuctionTypeDescription[AuctionTypeSearchType.None] = 'Все'; // OK
AuctionTypeDescription[AuctionTypeSearchType.Promo] = 'Промо лоты';
AuctionTypeDescription[AuctionTypeSearchType.OpenAuction] = 'Открытые аукционы';
AuctionTypeDescription[AuctionTypeSearchType.BuyNow] = 'Купить сейчас';

//!!! даный enum должен строго соответствовать enum типу на беке в файле - Mithra.Core.Utils.SearchTypes
export enum TradeTypeSearch {
    None = 0,
    Open = 1,
    Closed = 2
}

export var TradeTypeDescription: { [key: TradeTypeSearch]: string; } = {};
TradeTypeDescription[TradeTypeSearch.None] = 'Все'; // OK
TradeTypeDescription[TradeTypeSearch.Open] = 'Открытые продажи';
TradeTypeDescription[TradeTypeSearch.Closed] = 'Закрытые продажи';

//!!! даный enum должен строго соответствовать enum типу на беке в файле - Mithra.Core.Utils.SearchTypes
export enum TimeSearchType {
    None = 0,
    Nearest,
    New,
    Finished,
    Trading,
    Finishing10Mins,
    AllowAcceptance,
    AllowAcceptance10Mins
}

export var TimeDescription: { [key: TimeSearchType]: string; } = {};
TimeDescription[TimeSearchType.None]                  = 'Все'; // OK
TimeDescription[TimeSearchType.Nearest]               = 'Ближайшие';
TimeDescription[TimeSearchType.New]                   = 'Новые';
TimeDescription[TimeSearchType.Finished]              = 'Завершённые';
TimeDescription[TimeSearchType.Trading]               = 'Идёт торг';
TimeDescription[TimeSearchType.Finishing10Mins]       = 'До конца торга 10 минут';
TimeDescription[TimeSearchType.AllowAcceptance]       = 'Идёт приём заявок';
TimeDescription[TimeSearchType.AllowAcceptance10Mins] = 'До конца приёма заявок 10 минут';

export enum TradeTypes {
    None = 0,
    OpenAuction = 1,
    ClosedAuction = 32,
    PublicOffer = 33,
    Competition = 34,
    DecreaseOpenAction = 36,
    OpenAuctionWithInterval = 37
}

export enum TradeBuyTypes {
    Buy = 1,
    Sell = 2,
}

// данный enum содержит типы статусов оплаты лота и комиссии
export enum PaymentLotStatus {
    NoPaid = 0,
    PaidComission = 1,
    Paid = 2,
    Cancel = 3
}
// создадим словарь значений и текстов для enum PaymentLotStatus
export const PaymentLotStatusMap = new Map();
PaymentLotStatusMap.set(PaymentLotStatus.NoPaid, "Не оплачен");
PaymentLotStatusMap.set(PaymentLotStatus.PaidComission, "Оплачена комиссия");
PaymentLotStatusMap.set(PaymentLotStatus.Paid, "Оплачен");
PaymentLotStatusMap.set(PaymentLotStatus.Cancel, "Отменён");

export const PaymentLotStatusMap2 = new Map();
PaymentLotStatusMap2.set("Не оплачен", PaymentLotStatus.NoPaid);
PaymentLotStatusMap2.set("Оплачена комиссия", PaymentLotStatus.PaidComission);
PaymentLotStatusMap2.set("Оплачен", PaymentLotStatus.Paid);
PaymentLotStatusMap2.set("Отменён", PaymentLotStatus.Cancel);

export enum TradeStates{
    New = 1,
    Approved = 32,
    Declined = 33,
    AllowAcceptance = 34,
    WaitingForTrade = 35,
    Trading = 36,
    Traded = 37,
    Completed = 38,
    StopedByOrganizer = 39,
    WaitingForConfirmation = 40,
    ApplicationProcessing = 41,
    TradeAbandoned = 42,
    LotTradeStatus = 43,
    DeclinedByOrganizer = 44
}

export enum PriceReduceTypes {
    FixedBasePriceReduce = 1,
    PercentBasePriceReduce = 2,
    PercentPrevPriceReduce = 3,
    FixedPrevPriceReduce = 4,
    FixedReduce = 5,
}

export enum CompanyTypes {
    OOO = 1,
    IP = 2,
    FL = 3
}

export interface ILotModel {
    Id: number,
    Name: string,
    TradeId: number,
    TradeTypeId: Nullable<number>,
    IsOpenPriceForm: Nullable<boolean>,
    IsOpenForm: Nullable<boolean>,
    WithoutLegalForce: Nullable<boolean>,
    WithoutApplicationsAcceptance: Nullable<boolean>,
    TradeBuyTypeId: Nullable<number>,
    Category: string,
    Information: string,
    BasePrice: string,
    BuyNowPrice: string,
    StepPercent: Nullable<number>,
    StepValue: Nullable<number>,
    StepDayInterval: Nullable<number>,
    PriceReduceTypeId: Nullable<number>,
    DepositType: string,
    DepositSize: string,
    DepositPercent: Nullable<number>,
    DepositDate: string,
    DepositReturnDate: string,
    DepositRules: string,
    DepositeIsRequired: Nullable<boolean>,
    RestartIsRequired: Nullable<boolean>,
    PriceReduceUnitMeasurement: string,
    PriceIncreaseUnitMeasurement: string,
    ReduceStepSize: Nullable<number>,
    IncreaseStepSize: Nullable<number>,
    IncreaseInvervalStart: Nullable<number>,
    IncreacseInvervalEnd: Nullable<number>,
    ReduceInterval: Nullable<number>,
    ReduceLimitValue: Nullable<number>,

    LotTradeStateId: Nullable<number>,
    OldStateId: Nullable<number>,
    CategoriesIds: Array<number | string>,

    LotApplicationPeriodStart: Nullable<Date>,
    LotApplicationPeriodEnd: Nullable<Date>,
    LotTradePeriodStart: Nullable<Date>,
    LotTradePeriodEnd: Nullable<Date>,
    ReductionLowerBoundPercent: Nullable<number>,
    DepositBankAccountId: Nullable<number>,
    PriceReduceAfterMinutes: Nullable<number>,
    TradePeriodEndAfterLastBetMinutes: Nullable<number>,
    BasePriceReducePercent: Nullable<number>,
    AuctionStepType:  number,

    AvailableBuyTypes: Array<IBuyType>,
    AvailableTradeTypes: Array<ISelectListItem>,
    AvailablePriceReduceTypes: Array<PriceReduceTypeModel>,
    AvailablePriceIncreaseTypes: Array<PriceReduceTypeModel>,
    AvailableDepositCalculationTypes: Array<ISelectListItem>,
    AvailableDepositBankAccounts: Array<ISelectListItem>,
    AvailableCategories: Array<AvailableCategoryModel>,
    AvailableDocumentTypes: Array<ISelectListItem>,
    AvailableCompanyCustomers: Array<ICompanyCustomerModel>,

    Address: IAddressModel,
    FiltersValues: Array<ILotFilterValueModel>,
    Pictures: Array<IPictureModel>,
    Intervals: Array<IIntervalModel>,

    Documents: Array<IDocumentModel>,
    TradeCertifiedCustomers: Array<number>,

    Video: string,
    TradePeriodLength: number,
    AllowBids: boolean,
    Quantity: string,
    ShippingPaymentType: Nullable<number>,
    ShippingMethodId: Nullable<number>,
    PaymentMethodId: Nullable<number>,
    DealType: Nullable<number>,

    AvailableShippingMethods: Array<ISelectListItem>,
    AvailablePaymentMethods: Array<ISelectListItem>,
    AvailableShippingPaymentTypes: Array<ISelectListItem>,
    AvailableDealTypes: Array<ISelectListItem>,

    Tags: Array<ITagModel>

    IsClosedTrade: boolean,
    IsPreorder: boolean
}

export interface ILotEditorModel {
    Lot: ILotModel,
    ParentCategoryId: Nullable<number>,
    SubCategoryId: Nullable<number>,
    ThirdLevelCategoryId: Nullable<number>,
    ShowValidation: boolean,
    ImageFiles: Array<any>,
    ImagePath: Nullable<any>,
    Errors: IErrorDictionary,
    AvailableAllowTypes: Array<ISelectListItem>
    AvailableLengthPeriods: Array<ISelectListItem>,
    AvailableYesNoTypes: Array<ISelectListItem>,
    AvailableUnitsOfMeasurement: Array<ISelectListItem>,
    AvailableAuctionStepTypes: Array<ISelectListItem>,
    TradeStartType: number,
    AvailableCities: Array<any>,
    DepositeIsRequired: Nullable<boolean>,
    RestartIsRequired: Nullable<boolean>,
    TagsSearch: any,
    IsButtonLoading: boolean,
    IsApplied: boolean,
    LotUrl: string,
    FullLotUrl: string,
    CitiesLoaded: boolean,
    VideoErrorMessage: string,
    ActivateDate: Nullable<Date>,
    IsLegalForce: boolean,
    IsClosedTrade: boolean,
    IsPreorder: boolean,
    CustomFiltersError: Array<ICustomFiltersError>
}

export interface ICustomFiltersError {Ы
    filterId: number,
    isValid: boolean,
    message: string
}

export interface IDimensions {
    Width: number,
    Height: number,
    Length: number,
    Weight: number
}

export interface IDeliveryStartPoint {
    CountryCode: string,
    City: string,
    Address: string
}

export interface IDeliveryData {
    Dimensions: IDimensions,
    DeliveryStartPoint: IDeliveryStartPoint
}
export enum TradeStartTypes {
    Now,
    SelectedDate,
    NoTrade
}

export interface AvailableCategoryModel {
    Id: number,
    Name: string,
    SecondName:string,
    ParentId: Nullable<number>,
}

export interface PriceReduceTypeModel {
    Id: number,
    Description: string,
    IntervalDescription: string,
}
export interface IAddressModel {
    Id: number,
    StateProvinceId: Nullable<number>,
    CityId: Nullable<number>,
    CityName: string,
    CityDistrict: string,
    Address1: string,
    Address2: string,
    FullAddress: string,
    ZipPostalCode: string,
    StateProvince: string,
    PhoneNumber: string,
    Latitude: Nullable<number>,
    Longitude: Nullable<number>,
    CityFullName: string,
    AvailableStates: Array<ISelectListItem>,
}

export interface ILotFilterValueModel {
    FilterId: number,
    FilterValue: string,
    FilterValuesIds: Array<number>
}

export interface ILotEditorFilterModel {
    FilterId: number,
    Index: number,
    FilterName: string,
    FilterTypeId: number,
    FilterUserValueTypeId: Nullable<number>,
    FilterValue: string,
    FilterValuesIds: Array<number | string>,
    AvailableFilterValues: Array<ISelectListItem>,
    CategoriesIds: Array<number>,
    ControlTypeId: Nullable<number>,
    IsRequired: boolean,
    AllowToUseForFiltering: boolean,
    CurrencyLabelEnabled: boolean,
    ChangeAddress: boolean,
    ParentFilterValueId: number,
    Placeholder: string,
    WidthPoints: number,
    RowsCount: number,
    GroupId: number,
    GroupName: string
}

export enum FilterTypes {
    List = 1,
    ListOneValue = 2,
    UserValue = 3,
    RangeValue = 4
}

export enum FilterControlTypes {
    DropDown = 1,
    CheckBoxList = 2,
    TextInput = 3,
    DateTime = 4,
    AddressAutocomplete = 5
}

export enum FilterUserValueTypes {
    Number = 1,
    String = 2
}

export interface IPictureModel {
    PictureId: number,
    PictureFileName: string,
    DisplayNumber: number,
    Url: string,
    ContentType: string,
    PictureFile: any,
    FullUrl: string,
}

export interface ITagModel {
    Id: number,
    TagName: string,
}

export interface IIntervalModel {
    Id: number,
    Ind: Nullable<number>,
    LotPriceReduceTypeId: number,
    CompanyId: number,
    LotId: number,
    LotInd: number,
    TradeSessionId: number,
    StartPriceDate: Date,
    ApplicationStartPriceDate: Nullable<Date>,
    ApplicationEndPriceDate: Nullable<Date>,
    EndPriceDate: Date,
    Comment: string,
    IntervalDeposite: Nullable<number>,
    IntervalStepName: string,
    IntervalStep: Nullable<number>,
    Price: number,
    LotApplicationPeriodStart: Date,
    BasePrice: number,
}  

export interface IAddDocumentModel {
    Comment: string,
    DocumentFile: any,
    DocumentFileName: string,
    DocumentTypeId: Nullable<number>,
}

export interface IDocumentModel {
    DocumentId: number,
    Name: string,
    Comment: string,
    DocumentTypeId: number,
    CreationDate: Date,
    IsSigned: boolean,
    SignStatusId: Nullable<number>,
    SignStatusDescription: string,
    DocumentTypeDescription: string,
    CurrentUserCanSign: boolean,
    SignatureLink: string,
    Signature: string,
    DataToSign: string,
    DocumentLink: string
}

export interface IListLot {
    Id: number,
    Name: string,
    SeoUrl: string,
    TradeTypeId: number,
    TradeTypeDescription: string,
    LotTradeStateId: Nullable<number>,
    LotTradeStateDescription: string,
    Information: string,
    BasePrice: number,
    CurrentPrice: Nullable<number>,
    BuyNowPrice: Nullable<number>,
    CurrencyId: number,
    Currency: string,
    CurrencyCode: string,
    Address: IAddressModel,
    DiscountPercent: Nullable<number>,
    TradeId: number,
    TradeContactPhone: string,
    ValidParticipant: boolean,
    CanBeBought: boolean,
    CurUserFavorite: boolean,
    UserLoggedIn: boolean,
    LotApplicationPeriodStart: Nullable<Date>,
    LotApplicationPeriodEnd: Nullable<Date>,
    LotTradePeriodStart: Nullable<Date>,
    LotTradePeriodEnd: Date,
    WithoutApplicationsAcceptance: boolean,
    MainPictures: Array<IPictureModel>,
    Quantity: number,
    Seller: string,
    LotPricesCount: number
    ViewsCount: number,
    FavouritesCount: number,
    AllowReview: boolean,
    BuyerId: number,
    BuyerName: string,
    IsClosedTrade: boolean,
    IsPreorder: boolean
}

export interface ICompanyCustomerModel {
    Id: number,
    FIO: string,
    Status: string,
    HasCertificate: boolean,
}

export interface IBuyType {
    Id: number,
    ParentId: number,
    Title: string,
    Description: string
}

export enum LotActions {
    CopyLot = 1,
    CopyLotWOPictures = 2,
    FinishTrade = 3,
    DeleteLot = 4,
    ApplyLot = 11
}

export enum LotMassActions {
    FinishTrade = 3,
    DeleteLot = 4,
    ApplyLot = 11
}

export interface ICitySearchModel {
    CityId: number,
    StateProvinceId: number,
    Name: string,
}

export interface IAddressAutocompleteModel {
    CityId: number,
    StateProvinceId: number,
    Address1: string,
    Address2: string,
    Name: string,
    Ltd: string,
    Lng: string
}

export interface ITypingMessage{
    LotId: number;
    OwnerId?: Nullable<number>
    IsTyping: boolean;
}

export interface ISendChatMessage{
    LotId: number;
    OwnerId?: Nullable<number>
    TextBody: string;
}

export interface IChatMessage {
    OwnerId: number;
    OwnerDisplayName: string;
    RecipientId: number;
    RecipientDisplayName: string;
    LotId: number;
    LotName: string;
    TextBody: string;
    SentDate: Date;
}

export interface IDocumentType {
    Id: number,
    Name: string,
    FolderName: string,
    Suffix: string,
    IsSystem: boolean,
    DisplayOrder: Nullable<number>
    Description: string
}

export interface IDocument {
    Id: number,
    DocumentType: IDocumentType,
    IsSignature: boolean
}

export enum ChatUserStatus{
    Offline = 0,
    Online = 1
}

export enum DocumentTypes {

    CopyOfCertificateOfIdentity = 9,
    Other = 34,
    Contract = 39
} 

export interface IPurchasedService {
    Name: string;
    PaymentDate: Date;
    Price: number;
}