import React, { useState, useEffect, useRef, useCallback } from "react";
import history from "../history";
import { useSignal, useSlot } from 'react-signal-slot'


// данная модель используется для передечи через неё данных контроллеру и хранимой процедуре
// необходимо cтрого поддерживать соответвтие её полей классу Mithra.Data.Models.Catalog.CatalogModel
// так как этот класс сериализуется в json и передаётся на сервер, где его принимают в качетсве модели данных Mithra.Data.Models.Catalog.CatalogModel


function TestArea()
{
    useSlot('TestSignal', (type) => testSlot(value)); // subscribe the signal of "signal"

    const testSlot = (value) => {

    }

    return (
        <React.Fragment>

        </React.Fragment>
    );
}

export default TestArea;