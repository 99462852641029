import React, { useState, useEffect } from "react";
import { CardLotModel } from "../Catalog/types"
import CardLot from "../Catalog/CardLot"
import CardLotCloseSales from "../Catalog/CardLotCloseSales"
import { fetcherPOSTJSON } from "../Common/Fetch";
import history from "../history";
import { FilterModel } from "./types"
import { AuctionTypeSearchType, TradeTypeSearch, TimeSearchType } from "../Common/types"


function PromoLotsList() {
    const [lots, setLots] = useState<Array<CardLotModel>>([]);
    const [filters, setFilters] = useState<FilterModel>(
        {
            CategoryId: 0,
            CurrentPage: 1,
            CountLotsOnPage: 3,
            AuctionType: 0,
            SalesType: 0,
            TimeSearch: 0,
            StartDate: null,
            EndDate: null,
            StartPrice: 0,
            EndPrice: 0,
            TextSearch: "",
            dateInputLocale: null
        }
    );
    const [categoryFilters, setCategoryFilters] = useState([
        { name: 'Все', isActive: true, categoryId: 0 },
        { name: 'Жилая недвижимость', isActive: false, categoryId: 28 },
        { name: 'Лизинг', isActive: false, categoryId: 168 },
        { name: 'Коммерческая недвижимость', isActive: false, categoryId: 29 },
        { name: 'Оборудование', isActive: false, categoryId: 158 }
    ]);

    const [isLoading, setIsLoading] = useState(false);

    // Получение лотов
    const updateCategory = (newCategoryId:number) => {
        setIsLoading(true);

        setFilters({ ...filters, CategoryId: newCategoryId })
        console.log('Смена категории');
        console.log('Новый фильтр:' + JSON.stringify(filters))
    }

    useEffect(() => {

        var json = JSON.stringify(filters);
        fetcherPOSTJSON("/Category/GetListLots", json, function (result) {
            if (result.Success == true) {
                console.log(result.lots)
                setLots(result.lots);
                setIsLoading(false);
            }
            else {
                console.log('error!');
            }
        });
    }, [filters])

    const onIsFavoriteToggle = () => {
        
    }

    // Устанавливает в ключи isActive функции, которые будут возвращать true/false для визуального обозначения кнопок
    const handleChangeFilter = (eo) => {
        setCategoryFilters([
            { name: 'Все', isActive: changeActiveCategory('Все', eo.target.textContent, 0), categoryId: 0 },
            { name: 'Жилая недвижимость', isActive: changeActiveCategory('Жилая недвижимость', eo.target.textContent, 28), categoryId: 28 },
            { name: 'Лизинг', isActive: changeActiveCategory('Лизинг', eo.target.textContent, 168), categoryId: 168 },
            { name: 'Коммерческая недвижимость', isActive: changeActiveCategory('Коммерческая недвижимость', eo.target.textContent, 29), categoryId: 29 },
            { name: 'Оборудование', isActive: changeActiveCategory('Оборудование', eo.target.textContent, 158), categoryId: 158 }
        ])
    }

    // Проверяет значение нажатого фильтра, устанавливает isActive true объекту этой кнопки
    // и вызывает фукнцию updateCategory для получения лотов по выбранной категории
    const changeActiveCategory = (name, choice, categoryId) => {
        for (let i of categoryFilters) {
            if (name == choice) {
                updateCategory(categoryId);
                return true;
            } else {
                return false;
            }
        }
    }

    return (
        <>
            <h2>Специальные предложения</h2>
            <div className="lotFilters">
                {categoryFilters.map(filterItem => (
                    <span className={filterItem.isActive ? 'filterItem active' : 'filterItem'} onClick={handleChangeFilter}>{filterItem.name}</span>
                ))}
            </div>
            <div className="promoLotsList scrollContainer2" id="promoLotsList">
                {isLoading ?
                    (
                        <img src="/Content/images/loading_sm.gif" style={{width: "100px"}} />
                    ) : (
                        lots.length > 0 ? lots.map(lot => (
                            <div key={lot.LotId} className="sub-category-card promoLot">
                                {lot.IsClosedTrade ?
                                    <CardLotCloseSales lot={lot} onIsFavoriteToggle={onIsFavoriteToggle} />
                                    :
                                    <CardLot lot={lot} onIsFavoriteToggle={onIsFavoriteToggle} />
                                }
                            </div>
                        )) : <p className="defaultDescription">К сожалению, по вашему запросу ничего не найдено</p>
                    )
                }
                
            </div> 
            <a className="pinkBorderButton" href="/Category/Details/">Все специальные предложения </a>
        </>
    );
}

export default PromoLotsList;

