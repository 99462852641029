import React, { useState, useEffect } from "react";
import { CardLotModel } from "../Catalog/types"
import CardLot from "../Catalog/CardLot"
import CardLotCloseSales from "../Catalog/CardLotCloseSales"
import { fetcherPOSTJSON } from "../Common/Fetch";
import history from "../history";
import { FilterModel } from "./types"
import { AuctionTypeSearchType, TradeTypeSearch, TimeSearchType } from "../Common/types"
import { DEFAULT_LOT_IMAGE, LOT_DETAILS_URL } from "../settings";


function NearestLots() {
    const [lots, setLots] = useState<Array<CardLotModel>>([]);
    const [filters, setFilters] = useState<FilterModel>(
        {
            CategoryId: 0,
            CurrentPage: 1,
            CountLotsOnPage: 6,
            AuctionType: 0,
            SalesType: 0,
            TimeSearch: 1,
            StartDate: null,
            EndDate: null,
            StartPrice: 0,
            EndPrice: 0,
            TextSearch: "",
            dateInputLocale: null
        }
    );

    // Получение лотов
    useEffect(() => {
        var json = JSON.stringify(filters);
        console.log('filters = ', json);
        fetcherPOSTJSON("/Category/GetListLots", json, function (result) {
            if (result.Success == true) {
                setLots(result.lots);
            }
            else {
                console.log('error!');
            }
        });
    }, []);

    const onIsFavoriteToggle = () => {
        
    }

    return (
        <>
            <h2>Ближайшие торги</h2>
            <div className="nearestLots">
                {lots.length > 0 ? lots.map(lot => (
                    <div key={lot.LotId} className="nearestLot">
                        <a href={lot.SeoUrl ? "/" + lot.SeoUrl : LOT_DETAILS_URL + lot.LotId} data-attribute-URL_={lot.SeoUrl ? "/" + lot.SeoUrl : LOT_DETAILS_URL + lot.LotId}>
                            {(lot.ImagePath && lot.ImagePath.length > 0) ?
                                <img key={lot.ImageId} src={lot.FullImagePath} alt={lot.ImageName} />
                                :
                                <img src="../../main-images/defaultLotImage.png" alt="Картинка по умолчанию" />
                            }
                        </a>
                        <div className="lotInfo">
                            <h4>{lot.Price}</h4>
                            <p className="defaultDescription">{lot.LotName}</p>
                        </div>
                    </div>
                )) : <p className="defaultDescription">К сожалению, ближайших лотов не найдено</p>}
            </div> 
            <a className="pinkBorderButton" href="/Category/Details/">Перейти к торгам</a>
        </>
    );
}

export default NearestLots;

